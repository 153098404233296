import React from 'react'
import { CSSTransitionGroup } from 'react-transition-group'
import '../styles/homeStyle.css'
import { Link } from 'react-router-dom'

const Home = () => {
  return (
    <CSSTransitionGroup 
      transitionName="homeTransition"
      transitionAppear={true}
      transitionAppearTimeout={500}
      transitionEnter={false}
      transitionLeave={false}>
      <div className="content-wrapper">
	      <div className="App">
	      	<img className="img-logo" src="./img/portfolio.jpg" alt="jamilswork.com" />
	      	<h1 className="App-title">
	      		Hi, my name is Jamil 
	      		<span>a Web Developer living in sunny San Diego, CA.</span>
	      	</h1>
	      	<p className="App-intro">
				I enjoy building and creating new things. I particularly enjoy building beautiful websites and web apps. Here are a few projects I built this year:
	        </p>
	        <div className="movies">
	        	<Link to="/1" className="movie">
			        <div><img width="100%" src="./img/jamilslist-poster.jpg" alt="jamilslist.com" /></div>
			    </Link>
			    <Link to="/2" className="movie">
			        <div><img width="100%" src="./img/costume-grab-poster.jpg" alt="costumegrab.com" /></div>
			    </Link>
			    <Link to="/3" className="movie">
			        <div><img width="100%" src="./img/dr-mike-poster.jpg" alt="drmikediet.com" /></div>
			    </Link>
		    </div>
	      </div>
      </div>
    </CSSTransitionGroup>
  )
}

export default Home
