import React from 'react'
import { CSSTransitionGroup } from 'react-transition-group'
import '../styles/allStyle.css'
import { Link } from 'react-router-dom'

const DrMike = () => {
  return (
    <CSSTransitionGroup
      transitionName="worksTransition"
      transitionAppear={true}
      transitionAppearTimeout={500}
      transitionEnter={false}
      transitionLeave={false}>
      	<div className="content-wrapper clearfix">
	       <div className="App">
	       <Link to="/" className="back-arrow">
	       <span>
	       	&larr; &nbsp;
	       </span>
	       </Link>
	       	<h1 className="text-center App-title">Dr. Mike Diet: The 17 Day Diet</h1>
	      	<div className="width-50">
	      		<img className="main-img" width="100%" src="./img/dr-mike-poster.jpg" alt="drmikdiet.com" />
	      		<ul className="built-with">
	      			<li>- HTML5</li>
	      			<li>- CSS3</li>
	      			<li>- Wordpress</li>
	      			<li>- PHP</li>
	      			<li>- MySQL</li>
	      			<li>- Kartra API integration</li>
	      		</ul>
	      	</div>
	      	<div className="width-50 left">
	      		<p className="project-info">
	      		Dr. Mike is the New York Times Best Selling author of the "17 Day Diet".  I created this Wordpress theme from scratch. All pages were created from PSD's.	
	      		</p>
	      		<p className="project-info">
	      		I designed this Wordpress theme so that all aspects of the site are dynamic and manageable by users on the backend. The blog functionality was created with custom AJAX.
	      		</p>
	      		<p className="project-info">
	      		In addition to creating the theme from scratch, I was able to optimize the Apache2 server to bring a large amount of rankings from a grade of F to A. Along side the server optimization I increased the site speed from 50 to 91 on both mobile and desktop through different optimization techniques.
	      		</p>
	      		<p className="project-info">
	      		Dr. Mike has an email list of hundreds of thousands of subscribers and I maintain marketing funnels to ensure high levels of profitability.
	      		</p>
	      		<a className="view-site" rel="noopener noreferrer"  target="_blank" href="https://www.drmikediet.com">View Site</a>
	      	</div>
	      </div>
        </div>
    </CSSTransitionGroup>
  )
}

export default DrMike
