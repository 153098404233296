import React, { Component } from 'react';
import Content from './components/Content'
import './styles/App.css';
 
class App extends Component {
  render() {
    return (
      <div>
        <Content />
      </div>
    );
  }
}
 
export default App;