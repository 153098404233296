import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Home from './Home'
import Jamilslist from './Jamilslist'
import CostumeGrab from './CostumeGrab'
import DrMike from './DrMike'
 
const Content = () =>{
  return( 
    <Switch>
      <Route exact path="/" component={Home}/>
      <Route path="/1" component={Jamilslist}/>
      <Route path="/2" component={CostumeGrab}/>
      <Route path="/3" component={DrMike}/>
    </Switch>
  )
}
 
export default Content