import React from 'react'
import { CSSTransitionGroup } from 'react-transition-group'
import '../styles/allStyle.css'
import { Link } from 'react-router-dom'

const Jamilslist = () => {
  return (
    <CSSTransitionGroup
      transitionName="worksTransition"
      transitionAppear={true}
      transitionAppearTimeout={500}
      transitionEnter={false}
      transitionLeave={false}>
      	<div className="content-wrapper clearfix">
	       <div className="App">
	       <Link to="/" className="back-arrow">
	       <span>
	       	&larr; &nbsp;
	       </span>
	       </Link>
	       	<h1 className="text-center App-title">Jamilslist: Netflix Movie App</h1>
	      	<div className="width-50">
	      		<img className="main-img" width="100%" src="./img/jamilslist-poster.jpg" alt="jamilslist.com" />
	      		<ul className="built-with">
	      			<li>- HTML5</li>
	      			<li>- CSS3</li>
	      			<li>- Angular 4</li>
	      			<li>- PHP</li>
	      			<li>- MySQL</li>
	      			<li>- TMDB API</li>
	      			<li>- Express Server (Server-side rendering)</li>
	      		</ul>
	      	</div>
	      	<div className="width-50 left">
	      		<p className="project-info">
	      		Jamilslist.com was created out of the need to find good movies streaming on Netflix everynight and the lack of trustworthy resources to find them.  It was a fun project created in a few months that has a decent amount of traffic and an ever growing subscriber list.	
	      		</p>
	      		<p className="project-info">
	      		The site contains an admin area for admins to login, select movies, and leave reviews for Netflix movies and TV Shows.
	      		</p>
	      		<p className="project-info">
	      		The TMDB API was used to pull in selected movies and the backend of the application was written in vanilla PHP with a MySQL database.	
	      		</p>
	      		<p className="project-info">
	      		The frontend of Jamilslist.com was created with Angular 4. I also had to create an Express server to allow for server-side rendering and SEO.	
	      		</p>
	      		<a className="view-site" rel="noopener noreferrer"  target="_blank" href="https://www.jamilslist.com">View Site</a>
	      	</div>
	      </div>
        </div>
    </CSSTransitionGroup>
  )
}

export default Jamilslist
