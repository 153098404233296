import React from 'react'
import { CSSTransitionGroup } from 'react-transition-group'
import '../styles/allStyle.css'
import { Link } from 'react-router-dom'

const CostumeGrab = () =>{
  return(
   <CSSTransitionGroup
      transitionName="worksTransition"
      transitionAppear={true}
      transitionAppearTimeout={500}
      transitionEnter={false}
      transitionLeave={false}>
      <div className="content-wrapper clearfix">
	       <div className="App">
	       <Link to="/" className="back-arrow">
	       <span>
	       	&larr; &nbsp;
	       </span>
	       </Link>
	       	<h1 className="text-center App-title">CostumeGrab: Listing App</h1>
	      	<div className="width-50">
	      		<img className="main-img" width="100%" src="./img/costume-grab-poster.jpg" alt="costumgrab.com" />
	      		<ul className="built-with">
	      			<li>- HTML5</li>
	      			<li>- CSS3</li>
	      			<li>- Angular 4</li>
	      			<li>- Django Rest Framework (Python) API</li>
	      			<li>- MySQL</li>
	      			<li>- NativeScript</li>
	      		</ul>
	      	</div>
	      	<div className="width-50 left">
	      		<p className="project-info">
	      		CostumeGrab.com was created for communities to be able to buy and sell costumes online for free.  Since launching this year, Costume Grab already has hundreds of users and the listings continue to grow.
	      		</p>
	      		<p className="project-info">
	      		The site contains an admin area for users to upload images of costumes and manage their listings.
	      		</p>
	      		<p className="project-info">
	      		The API was written from the ground up with the Django Rest Framework connected to a MySQL database. The API is currently running in a Docker container.
	      		</p>
	      		<p className="project-info">
	      		The frontend of Costumegrab.com was created with Angular 4. 
	      		</p>
	      		<p className="project-info">
	      		After completion of the web app, I additionally created mobile applications for CostumeGrab with NativeScript. Both the <a href="https://itunes.apple.com/us/app/costumegrab/id1422062326?mt=8">IOS</a> and <a href="https://play.google.com/store/apps/details?id=org.nativescript.CostumeGrab
">Android</a> apps are currently listed on their respective platforms. 
	      		</p>
	      		<a className="view-site" rel="noopener noreferrer"  target="_blank" href="https://www.costumegrab.com">View Site</a>
	      	</div>
	      </div>
        </div>
    </CSSTransitionGroup>
  )
}
 
export default CostumeGrab